import { createContext } from 'react';

export interface ThemeContextProps {
  theme: string;
  toggleTheme: () => void;
}

const defaultContext = {
  theme: 'light',
  toggleTheme: () => undefined,
};

export const ThemeContext = createContext<ThemeContextProps>(defaultContext);
