import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  heroContainer: {
    position: 'relative',
    textAlign: 'center',
    marginBottom: '3rem',
  },
  centerHeroContent: {
    position: 'absolute',
    width: '100%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  root: {
    marginTop: '1rem',
    flexGrow: 1,
    display: 'flex',
  },
  tabContent: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      marginLeft: '10rem',
    },
  },
  tabIndicator: {
    right: 'unset',
  },
}));
