import { Action } from 'typesafe-actions';

export const AppActionTypes = {
  RESET_STORE: 'RESET_STORE',
  API_EXCEPTION: 'API_EXCEPTION',
};

export const AppActions = {
  resetStore: (): Action => ({
    type: AppActionTypes.RESET_STORE,
  }),
  notifyApiError: (): Action => ({
    type: AppActionTypes.API_EXCEPTION,
  }),
};
