import produce from 'immer';
import { AppActionTypes } from './actions';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (state = {}, action: any): any =>
  produce(state, () => {
    switch (action.type) {
      case AppActionTypes.RESET_STORE: {
        state = {};
        return;
      }
      default:
        return state;
    }
  });
