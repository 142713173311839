import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Route, Routes } from 'react-router';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Hidden, Drawer, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import bpLogoIconDark from 'assets/images/svgs/bp-logo-dark.png';
import bpLogoIconLight from 'assets/images/svgs/bp-logo-light.svg';
import { DrawerContext, DrawerContextProps } from '../../../helpers/state/withDrawerContext';
import { ThemeContext, ThemeContextProps } from '../../../helpers/state/withThemeContext';
import Footer from '../Footer';
import Contentful from '../../../constants/contentful';
import { Container } from '../styles';
import { useContentfulQuery } from '../../contentful/query/QueryHooks';
import Page from '../../contentful/page';
import NavLink from '../NavLink';
import RoundedButton from '../Button/RoundedButton';

const commonDataQuery = {
  id: Contentful.CommonData,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
      marginLeft: '1rem',
    },
    iconButtonRoot: {
      color: 'initial',
    },
    logoButton: {
      maxWidth: '20em',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    logo: {
      width: '3rem',
      height: 'auto',
      marginRight: '0.5em',
      [theme.breakpoints.down('sm')]: {
        width: '2rem',
      },
    },
    toolbar: {
      ...theme.mixins.toolbar,
      minHeight: '100px !important',
    },
    drawerPaper: {
      width: '100vw',
    },
    navLinkContainer: {
      marginLeft: 'auto',
    },
    navButton: {
      lineHeight: 1.5,
      marginLeft: '3rem',
    },
    main: {
      width: '100%',
      overflow: 'hidden',
    },
    link: {
      fontSize: '1.125rem',
      marginLeft: '3rem',
      display: 'inline-flex',
      verticalAlign: 'middle',
    },
    mobileDrawerContainer: {
      height: '100vh',
    },
    mobileDrawerItem: {
      padding: '1.5rem 3rem',
    },
  }),
);

const NavBar: React.FC = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { drawerItems, setDrawerItems } = useContext<DrawerContextProps>(DrawerContext);
  const classes = useStyles();
  const { theme: themeMode } = useContext<ThemeContextProps>(ThemeContext);
  const { data, fetched } = useContentfulQuery(commonDataQuery);

  useEffect(() => {
    if (!data) {
      return;
    }
    const navLinks = data.fields.navLinks.map((link) => ({
      text: link.fields.text,
      url: link.fields.url,
      isButton: link.fields.isButton,
    }));
    setDrawerItems(navLinks);
  }, [data]);

  const handleDrawerToggle = (): void => {
    setMobileOpen(!mobileOpen);
  };

  const renderNavItem = (navLink: Record<string, any>): JSX.Element => (
    <React.Fragment key={navLink.url}>
      {navLink.isButton ? (
        <RoundedButton
          data-qa="login-button"
          classes={{ root: classes.navButton }}
          color="primary"
          variant="contained"
          href={navLink.url}
          key={navLink.url}
        >
          {navLink.text}
        </RoundedButton>
      ) : (
        <NavLink
          className={classes.link}
          to={navLink.url}
          href={navLink.url}
          data-qa={`${navLink.url.slice(1)}-nav-link-header`}
          color="textPrimary"
          activeStyle={{
            textDecoration: 'underline',
          }}
          showArrow={false}
        >
          {navLink.text}
        </NavLink>
      )}
    </React.Fragment>
  );

  const renderAppBar = (
    <AppBar data-qa="app-bar-header-container" position="absolute" className={classes.appBar} color="transparent">
      <Container>
        <Toolbar className={classes.toolbar}>
          <IconButton
            data-qa="bp-logo-icon-header"
            edge="start"
            className={classes.logoButton}
            classes={{ root: classes.iconButtonRoot }}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            component={RouterLink}
            to={'/'}
            onClick={() => (mobileOpen ? handleDrawerToggle() : undefined)}
          >
            <img src={themeMode === 'dark' ? bpLogoIconLight : bpLogoIconDark} className={classes.logo} alt="BP logo" />
            <Typography variant="h5" color="initial">
              <strong>The Hub</strong>
            </Typography>
          </IconButton>

          <div className={classes.navLinkContainer}>
            <Hidden smDown>{drawerItems.map((navLink) => renderNavItem(navLink))}</Hidden>
            <Hidden mdUp>
              <>
                {drawerItems.filter((o) => o.isButton).map((navLink) => renderNavItem(navLink))}
                <IconButton
                  data-qa="hamburger-icon-header"
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                  onClick={handleDrawerToggle}
                >
                  {mobileOpen ? <CloseIcon /> : <MenuIcon />}
                </IconButton>
              </>
            </Hidden>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );

  const drawer = (
    <div className={classes.mobileDrawerContainer} data-qa="mobile-drawer-container">
      {renderAppBar}
      <div className={classes.toolbar} />
      <List data-qa="mobile-drawer-content">
        {drawerItems
          .filter((o) => !o.isButton)
          .map((item) => (
            <ListItem
              button
              data-qa={`${item.url.slice(1)}-mobile-nav-link`}
              key={item.text}
              component={RouterLink}
              to={item.url}
              onClick={handleDrawerToggle}
              classes={{ root: classes.mobileDrawerItem }}
            >
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      {renderAppBar}
      <nav aria-label="navigation">
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            SlideProps={{ direction: 'down' }}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.main}>
        <div className={classes.toolbar} />
        <Routes>
          <Route path="/" element={<Page />} />
          <Route path=":slug/*" element={<Page />} />
        </Routes>
        {fetched && <Footer data={data.fields.footer} />}
      </main>
    </div>
  );
};

export default NavBar;
