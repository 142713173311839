import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { PageProps } from '../../types/contentful/contentfulTypes';
import { useMatch } from 'react-router';
import { Container, Image, PageContainer } from '../../components/common/styles';
import styled from 'styled-components';
import { useStyles } from './styles';
import { Tabs, Tab, Hidden, Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import NavLink from 'components/common/NavLink';
import { HookQueryProps, useContentful } from 'react-contentful';
import { renderContentfulRichText } from '../../components/contentful/rich-text/RichText';

const BannerImage = styled(Image)`
  max-height: 35rem;
  object-fit: cover;
  width: 100%;
  opacity: 0.5;
`;

const GridIcon = styled(Image)`
  margin-left: 0;
`;

const tabProps = (index: any): Record<string, string> => ({
  id: `tab-${index}`,
  'aria-controls': `tabpanel-${index}`,
});

const faqArticleQuery = (articleName: string): HookQueryProps => ({
  contentType: 'faqArticle',
  query: {
    'fields.slug.sys.contentType.sys.id': 'navLink',
    'fields.slug.fields.url[in]': articleName,
  },
});

const FaqPage: React.FC<PageProps> = ({ pageContent }: PageProps) => {
  if (!pageContent) {
    return null;
  }

  /**
   *  valid routes are:
   *  /faq
   *  /faq/{category}
   *  /faq/{category}/{articleName}
   */
  const categoryMatch = useMatch('/:faq/:category');
  const articleMatch = useMatch('/:faq/:category/:article');

  const classes = useStyles();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  // TODO: figure out a way not to call this on initial render
  const { data } = useContentful(faqArticleQuery(articleMatch?.pathname || null));
  const [previousQuery, setPreviousQuery] = useState<string>();
  const articleFound = get(data, 'items[0]');

  useEffect(() => {
    if (articleMatch) {
      setPreviousQuery(articleMatch.pathname);
    }
  }, [articleMatch]);

  const handleTabChange = (_, newValue: number): void => {
    setSelectedTabIndex(newValue);
  };

  const validCategoryLinks = pageContent.categoryLinks.map((o) => o.fields.url);
  const categories = pageContent.categories.filter(
    (category: Record<string, any>) =>
      category.fields.parentLink.fields.url === pageContent.categoryLinks[selectedTabIndex].fields.url,
  );

  const renderTabs = (
    orientation: 'horizontal' | 'vertical',
    variant: 'standard' | 'scrollable' | 'fullWidth' = 'standard',
  ): JSX.Element => (
    <Tabs
      orientation={orientation}
      variant={variant}
      value={selectedTabIndex}
      onChange={handleTabChange}
      aria-label="FAQ tabs"
      classes={{ indicator: classes.tabIndicator }}
    >
      {pageContent.categoryLinks.map((categoryLink: any, index: number) => (
        <Tab
          key={index}
          label={categoryLink.fields.text}
          {...tabProps(index)}
          component={Link}
          to={categoryLink.fields.url}
        />
      ))}
    </Tabs>
  );

  const renderCategoryGrid = (category: Record<string, any>): JSX.Element => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <GridIcon src={category.fields.icon.fields.file.url} alt={category.fields.icon.fields.title} />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3">
          <b>{category.fields.title}</b>
        </Typography>
      </Grid>

      {category.fields.faqLinks.map((faqLink: Record<string, any>, index: number) => (
        <Grid item xs={12} key={index}>
          <NavLink to={faqLink.fields.url} showArrow={false} color="textPrimary">
            {faqLink.fields.text}
          </NavLink>
        </Grid>
      ))}
    </Grid>
  );

  // Don't render content if article not found or category does n't exist
  if (
    (articleMatch && !articleFound && articleMatch.pathname !== previousQuery) ||
    (categoryMatch && !validCategoryLinks.includes(categoryMatch.pathname))
  ) {
    return <h1>Page not found</h1>;
  }

  return (
    <Container>
      <Hidden lgUp>{renderTabs('horizontal', 'fullWidth')}</Hidden>

      <div className={classes.heroContainer}>
        <BannerImage
          src={pageContent.banner.fields.media.fields.file.url}
          alt={pageContent.banner.fields.media.fields.file.title}
        />
        <div className={classes.centerHeroContent}>
          <Typography variant="h1">
            <b>{pageContent.banner.fields.title}</b>
          </Typography>
        </div>
      </div>

      <PageContainer>
        <div className={classes.root}>
          <Hidden mdDown>{renderTabs('vertical', 'standard')}</Hidden>

          <div className={classes.tabContent}>
            {!articleMatch && (
              <Grid container spacing={3}>
                {categories.map((category: Record<string, any>, index: number) => (
                  <Grid item key={index} xs={12} lg={4}>
                    {renderCategoryGrid(category)}
                  </Grid>
                ))}
              </Grid>
            )}

            {articleFound && <>{renderContentfulRichText(data['items'][0].fields.articleContent)}</>}
          </div>
        </div>
      </PageContainer>
    </Container>
  );
};

export default FaqPage;
