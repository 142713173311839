import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';
import Colors from '../../styles/theme/colors';

export const iconColors = [
  '#F070A1',
  Colors.POPPY,
  '#E7A657',
  '#FF6600',
  '#2B47E6',
  Colors.LAVENDER,
  Colors.MINT,
  Colors.SKY,
  '#666666',
];

export const useStyles = makeStyles((theme) =>
  createStyles({
    heroBanner: {
      [theme.breakpoints.down('sm')]: {
        marginTop: '1rem',
      },
    },
    heroButton: {
      width: '100%',
      marginBottom: '2rem',
    },
    heroCards: {
      [theme.breakpoints.up('md')]: {
        position: 'relative',
        top: '-1rem',
        marginBottom: '5.25rem',
      },
      marginBottom: '2.25rem',
    },
    heroIconContainer: {
      width: '53px',
      height: '44px',
    },
    hubPurpose: {
      textAlign: 'center',
      paddingBottom: '1rem',
      '& .MuiTypography-h1.MuiTypography-gutterBottom': {
        margin: 0,
      },
    },
    rowSpacing: {
      marginBottom: '6.25rem',
      [theme.breakpoints.down('md')]: {
        marginBottom: '2.25rem',
      },
    },
    categoryIconContainer: {
      height: '74px',
      width: '74px',
      borderRadius: '14px',
      verticalAlign: 'middle',
      justifyContent: 'center',
      display: 'inline-flex',
    },
    launchBannerCtaBtn: {
      width: '30%',
      margin: '1rem 0',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    categoryCards: {
      [theme.breakpoints.down('sm')]: {
        padding: '1.5rem 0',
      },
    },
  }),
);
