import { Button, withStyles } from '@material-ui/core';

const RoundedButton = withStyles({
  root: {
    borderRadius: '0.5rem',
    lineHeight: 2.5,
  },
})(Button);

export default RoundedButton;
