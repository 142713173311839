import React from 'react';
import { useContentful } from 'react-contentful';
import { Route, useMatch } from 'react-router';
import OurMissionPage from '../../../pages/ourMission';
import Home from '../../../pages/home';
import FaqPage from 'pages/faq';

const Page: React.FC = () => {
  const match = useMatch(':slug/*');
  const { data, error, fetched, loading } = useContentful({
    contentType: 'page',
    query: {
      'fields.slug[in]': `/${match?.params?.slug || ''}`,
    },
  });

  if (loading || !fetched) {
    return null;
  }

  if (error) {
    console.error(error);
    return null;
  }

  if (!data || !data['items'].length) {
    return <h1>Page does not exist.</h1>;
  }

  const fields = data['items'][0].fields;
  const pageContent = fields.pageContent.fields;
  return (
    <>
      {fields.slug === '/' && <Home pageContent={pageContent} />}
      {fields.id === 'OUR_MISSION' && <OurMissionPage pageContent={pageContent} />}
      {fields.id === 'FAQ_PAGE' && <Route path={fields.slug} element={<FaqPage pageContent={pageContent} />} />}
    </>
  );
};

export default Page;
