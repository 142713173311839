import React, { ReactNode } from 'react';

import { Typography } from '@material-ui/core';
import { BLOCKS, Document, Node, Block } from '@contentful/rich-text-types';
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer';
import { Variant } from '@material-ui/core/styles/createTypography';

const CONTENTFUL_BLOCKS_TO_HTML: Record<string, Variant> = {
  paragraph: 'body1',
  'heading-1': 'h1',
  'heading-2': 'h2',
  'heading-3': 'h3',
  'heading-4': 'h4',
  'heading-5': 'h5',
  'heading-6': 'h6',
};

const renderTypography = (node: Node, children: Block): JSX.Element => (
  <Typography
    variant={CONTENTFUL_BLOCKS_TO_HTML[node.nodeType]}
    paragraph={node.nodeType === 'paragraph'}
    gutterBottom={true}
  >
    {children}
  </Typography>
);

export const defaultRichTextOptions: any = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: renderTypography,
    [BLOCKS.HEADING_1]: renderTypography,
    [BLOCKS.HEADING_2]: renderTypography,
    [BLOCKS.HEADING_3]: renderTypography,
    [BLOCKS.HEADING_4]: renderTypography,
    [BLOCKS.HEADING_5]: renderTypography,
    [BLOCKS.HEADING_6]: renderTypography,
  },
  renderText: (text) => {
    return text.split('\n').reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
};

export const renderContentfulRichText = (richTextDocument: Document, options?: Options): ReactNode =>
  documentToReactComponents(richTextDocument, { ...defaultRichTextOptions, ...options });
