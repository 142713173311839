import React from 'react';
import { Grid, createStyles, Typography, Hidden } from '@material-ui/core';
import { PageContainer } from '../../components/common/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Image } from '../../components/common/styles';
import { renderContentfulRichText } from '../../components/contentful/rich-text/RichText';
import { PageProps } from '../../types/contentful/contentfulTypes';

const useStyles = makeStyles((theme) =>
  createStyles({
    heroBanner: {
      margin: '3.5rem 0',
    },
    rowSpacing: {
      marginBottom: '6.25rem',
      [theme.breakpoints.down('md')]: {
        marginBottom: '2.25rem',
      },
    },
    whatWeDoContainer: {
      backgroundColor: 'rgb(110,189,146, 0.25)',
      padding: '7% 10%',
      marginLeft: 'calc(50% - 50vw)',
      marginRight: 'calc(50% - 50vw)',
      maxWidth: '1000%',
      width: 'auto',
      textAlign: 'center',
    },
    whatWeDoContent: {
      margin: 'auto',
      maxWidth: '1440px',
    },
    howHubStartedBanner: {
      marginTop: '-4%',
    },
  }),
);

const OurMissionPage: React.FC<PageProps> = ({ pageContent }: PageProps) => {
  const classes = useStyles();
  if (!pageContent) {
    return null;
  }
  return (
    <PageContainer>
      <Grid container justify="center" alignItems="center" className={classes.heroBanner}>
        <Grid item sm={6} xs={12}>
          <Typography variant="h1">{pageContent.heroBanner.fields.title}</Typography>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Image
            src={pageContent.heroBanner.fields.media.fields.file.url}
            alt={pageContent.heroBanner.fields.media.fields.title}
          />
        </Grid>
      </Grid>

      <div className={classes.whatWeDoContainer}>
        <div className={classes.whatWeDoContent}>{renderContentfulRichText(pageContent.whatWeDoDescription)}</div>
      </div>

      <Grid container alignItems="center" spacing={3}>
        <Grid item sm={6} xs={12} className={classes.howHubStartedBanner}>
          <Image
            src={pageContent.howHubStartedBanner.fields.media.fields.file.url}
            alt={pageContent.howHubStartedBanner.fields.media.fields.title}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="h1" gutterBottom>
            {pageContent.howHubStartedBanner.fields.title}
          </Typography>
          {renderContentfulRichText(pageContent.howHubStartedBanner.fields.description)}
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sm={9}>
          {renderContentfulRichText(pageContent.whatTheHubIsDescription)}
        </Grid>
      </Grid>

      <Grid container alignItems="center">
        <Grid item sm={6} xs={12}>
          <Typography variant="h1" gutterBottom>
            {pageContent.whyTheHubBanner.fields.title}
          </Typography>
          {renderContentfulRichText(pageContent.whyTheHubBanner.fields.description)}
        </Grid>
        <Grid item sm={6} xs={12}>
          <Image
            src={pageContent.whyTheHubBanner.fields.media.fields.file.url}
            alt={pageContent.whyTheHubBanner.fields.media.fields.title}
          />
        </Grid>
      </Grid>

      <Grid container justify="center" style={{ textAlign: 'center' }}>
        <Hidden smDown>
          <Grid item sm={3} />
        </Hidden>
        <Grid item xs={12} md={6}>
          {renderContentfulRichText(pageContent.whoTheHubIsForDescription)}
        </Grid>
        <Hidden smDown>
          <Grid item sm={3} />
        </Hidden>
      </Grid>
    </PageContainer>
  );
};

export default OurMissionPage;
