import UniversRoman from '../../assets/fonts/UniversBP/UniversRoman.ttf';
import UniversLight from 'assets/fonts/UniversBP/UniversLight.ttf';
import UniversBold from 'assets/fonts/UniversBP/UniversBold.ttf';

const universRoman = {
  fontFamily: 'Univers',
  fontStyle: 'normal',
  src: [`local('Univers Roman')`, `local('Univers-Roman')`, `url(${UniversRoman}) format('truetype')`].join(','),
};

const universLight = {
  fontFamily: 'Univers',
  fontStyle: 'normal',
  fontWeight: 'lighter',
  src: [`local('Univers Light')`, `local('Univers-Light')`, `url(${UniversLight}) format('truetype')`].join(','),
};

const universBold = {
  fontFamily: 'Univers',
  fontWeight: 'bold',
  fontStyle: 'normal',
  src: [`local('Univers Bold')`, `local('Univers-Bold')`, `url(${UniversBold}) format('truetype')`].join(','),
};

export default [universRoman, universLight, universBold];
