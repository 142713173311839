import { applyMiddleware, createStore, compose, combineReducers, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from '../../src/store/reducers';
import rootSaga from './sagas';
import { AppState } from 'store/AppState';

export default function configureStore(): Store<AppState> {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware];
  const store = createStore(
    combineReducers({
      ...reducers,
    }),
    {},
    compose(applyMiddleware(...middlewares)),
  );
  sagaMiddleware.run(rootSaga);

  return store;
}
