import React, { Component } from 'react';
import { Provider } from 'react-redux';
import store from './store/configureStore';
import adAuthProvider from './modules/auth/ad-auth-provider';
import { AzureAD } from 'react-aad-msal';
import { CONTENTFUL_DELIVERY_API, CONTENTFUL_SPACE_ID, USE_AD_AUTHENTICATION } from 'constants/env';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import NavBar from 'components/common/NavBar';
import { DrawerContextProvider } from 'helpers/state/withDrawerContext';
import { ThemeProvider } from '@material-ui/core';
import { useToggleTheme } from './helpers/hooks/useToggleTheme';
import { ThemeContext } from './helpers/state/withThemeContext';
import { getTheme } from './styles/theme';
import { ContentfulClient, ContentfulProvider } from 'react-contentful';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const contentfulClient = new ContentfulClient({
  accessToken: CONTENTFUL_DELIVERY_API,
  space: CONTENTFUL_SPACE_ID,
});

const ConnectedApp: React.FC = () => {
  const [theme, toggleDarkMode] = useToggleTheme();
  return (
    <ContentfulProvider client={contentfulClient}>
      <ThemeContext.Provider value={{ theme, toggleTheme: toggleDarkMode }}>
        <ThemeProvider theme={getTheme(theme)}>
          <Provider store={store}>
            <DrawerContextProvider>
              <BrowserRouter>
                <CssBaseline />
                <NavBar />
              </BrowserRouter>
            </DrawerContextProvider>
          </Provider>
        </ThemeProvider>
      </ThemeContext.Provider>
    </ContentfulProvider>
  );
};

class Root extends Component {
  render(): React.ReactNode {
    return USE_AD_AUTHENTICATION === 'true' ? (
      <AzureAD provider={adAuthProvider} forceLogin={true}>
        <ConnectedApp />
      </AzureAD>
    ) : (
      <ConnectedApp />
    );
  }
}

export default Root;
