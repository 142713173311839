import React from 'react';
import { NavLink as RouterNavLink, NavLinkProps as RouterNavLinkProps } from 'react-router-dom';
import { Link, LinkProps } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

interface OwnProps {
  showArrow?: boolean;
}

type Props = LinkProps & RouterNavLinkProps & OwnProps;

const useStyles = makeStyles(() =>
  createStyles({
    link: {
      fontSize: '1.125rem',
    },
    underline: {
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    arrow: {
      paddingLeft: '0.5rem',
    },
  }),
);

const NavLink: React.FC<Props> = ({ to, showArrow = true, children, ...rest }: Props) => {
  const classes = useStyles();
  return (
    <Link component={RouterNavLink} to={to} underline="none" className={classes.link} {...rest}>
      <span data-qa="nav-link-text" className={classes.underline}>
        {children}
      </span>
      {showArrow && (
        <span data-qa="nav-link-arrow" className={classes.arrow}>
          →
        </span>
      )}
    </Link>
  );
};

export default NavLink;
