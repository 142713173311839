import { createMuiTheme, Theme } from '@material-ui/core/styles';
import { css } from 'styled-components';
import UniversFonts from './UniversFonts';
import Colors from './colors';

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    phone: true;
    tablet: true;
    desktop: true;
  }
}

export const viewportSizes = {
  xs: 0,
  sm: 672,
  md: 1056,
  lg: 1312,
  xl: 1584,
  phone: 672,
  tablet: 1056,
  desktop: 1312,
  large: 1584,
};

const isLightTheme = (type: 'light' | 'dark'): boolean => type === 'light';

const baseTheme = createMuiTheme({
  breakpoints: {
    values: { ...viewportSizes },
  },
});

const createTheme = (type: 'light' | 'dark' | undefined): Theme => {
  return createMuiTheme({
    breakpoints: {
      values: { ...viewportSizes },
    },
    typography: {
      fontFamily: 'Univers, sans-serif',
      h1: {
        fontSize: '2.5rem',
        lineHeight: '3rem',
        fontWeight: 'bold',
        [baseTheme.breakpoints.down('tablet')]: {
          fontSize: '2.25rem',
          lineHeight: '2.25rem',
        },
      },
      h2: {
        fontSize: '2rem',
        lineHeight: '2.375rem',
        fontWeight: 'bold',
        [baseTheme.breakpoints.down('tablet')]: {
          fontSize: '1.8rem',
          lineHeight: '1.8rem',
        },
      },
      h3: {
        fontSize: '1.5625rem',
        lineHeight: '2rem',
        [baseTheme.breakpoints.down('tablet')]: {
          fontSize: '1.40625rem',
          lineHeight: '1.40625rem',
        },
      },
      h4: {
        fontSize: '1.25rem',
        lineHeight: '1.625rem',
        fontWeight: 'bold',
        [baseTheme.breakpoints.down('tablet')]: {
          fontSize: '1.125rem',
          lineHeight: '1.125rem',
        },
      },
      body1: {
        fontSize: '1.125rem',
        lineHeight: '1.5rem',
      },
      body2: {
        fontSize: '0.875rem',
        lineHeight: '1.125rem',
      },
      caption: {
        fontSize: '0.875rem',
        lineHeight: '1.125rem',
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [...UniversFonts],
          '.MuiTypography-h1.MuiTypography-gutterBottom': {
            marginBottom: '3rem',
          },
        },
      },
    },
    palette: {
      primary: {
        main: Colors.BP_GREEN,
      },
      secondary: {
        main: Colors.LIGHT_GREEN,
      },
      error: {
        main: Colors.ERROR,
      },
      text: {
        primary: isLightTheme(type) ? Colors.ALMOST_BLACK : Colors.WHITE,
      },
      type,
    },
  });
};

export const getTheme = (mode: string): Theme => {
  const paletteType = mode === 'light' ? 'light' : 'dark';
  return createTheme(paletteType);
};

export const media: Record<string, () => string> = Object.keys(viewportSizes).reduce((finalMedia, size) => {
  return {
    ...finalMedia,
    [size]: function (args) {
      return css`
        @media (max-width: ${viewportSizes[size]}px) {
          ${css`
            ${args.join()}
          `}
        }
      `;
    },
  };
}, {});
