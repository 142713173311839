import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import { AAD_CLIENT_ID, AAD_TENANT_ID, PUBLIC_URL } from 'constants/env';

// The auth provider should be a singleton. Best practice is to only have it ever instantiated once.
// Avoid creating an instance inside the component it will be recreated on each render.
// If two providers are created on the same page it will cause authentication errors.
const authProvider = new MsalAuthProvider(
  {
    auth: {
      authority: 'https://login.microsoftonline.com/' + AAD_TENANT_ID,
      clientId: AAD_CLIENT_ID,
      redirectUri: window.location.origin + PUBLIC_URL,
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: true,
    },
  },
  {
    scopes: ['openid'],
  },
  {
    loginType: LoginType.Redirect,
  },
);

export default authProvider;
