import React from 'react';
import { Typography, Grid, Hidden } from '@material-ui/core';
import { PageContainer, Image } from '../../components/common/styles';
import styled from 'styled-components';
import RoundedButton from 'components/common/Button/RoundedButton';
import Card from 'components/common/Card';
import { renderContentfulRichText } from '../../components/contentful/rich-text/RichText';
import { PageProps } from '../../types/contentful/contentfulTypes';
import { useStyles, iconColors } from './styles';

const BannerImage = styled(Image)`
  max-height: 35rem;
  object-fit: cover;
  margin-right: 0;
`;

const Home: React.FC<PageProps> = ({ pageContent }: PageProps) => {
  const classes = useStyles();

  return (
    <PageContainer>
      <Grid container alignItems="center" justify="center" alignContent="center" className={classes.heroBanner}>
        <Grid item xs={12} sm={4}>
          <Typography variant="h1" gutterBottom>
            {pageContent.heroBanner.fields.title}
          </Typography>

          <RoundedButton
            data-qa="smp-marketplace-button"
            href={pageContent.heroBanner.fields.cta.fields.navLink.fields.url}
            variant="contained"
            color="primary"
            className={classes.heroButton}
          >
            {pageContent.heroBanner.fields.cta.fields.title}
          </RoundedButton>
        </Grid>

        <Hidden xsDown>
          <Grid item xs={8}>
            <BannerImage
              src={pageContent.heroBanner.fields.media.fields.file.url}
              alt={pageContent.heroBanner.fields.media.fields.file.title}
            />
          </Grid>
        </Hidden>
      </Grid>

      <Grid container justify="center" className={classes.heroCards} spacing={3}>
        {pageContent.heroCards.map((card, index) => (
          <Grid item xs={12} md={3} key={index}>
            <Card
              data-qa={`card-${index}`}
              raised
              icon={
                <div className={classes.heroIconContainer}>
                  <img src={card.fields.image.fields.file.url} alt={card.fields.image.fields.file.title} />
                </div>
              }
              title={card.fields.title}
              linkTo={card.fields.navLink.fields.url}
              linkText={card.fields.linkText}
            />
          </Grid>
        ))}
      </Grid>

      <div className={classes.rowSpacing}>
        <div className={classes.hubPurpose}>{renderContentfulRichText(pageContent.hubPurpose)}</div>
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={12} md={6}>
            <Image
              src={pageContent.hubPurposeBanner.fields.media.fields.file.url}
              alt={pageContent.hubPurposeBanner.fields.media.fields.title}
            />
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={12} md={4}>
            {renderContentfulRichText(pageContent.hubPurposeBanner.fields.description)}
          </Grid>
        </Grid>
      </div>

      <div className={classes.rowSpacing}>
        <Grid container spacing={2}>
          {pageContent.categoryCards.map((card, index) => (
            <Grid item xs={12} md={4} key={index} className={classes.rowSpacing}>
              <Card
                data-qa={`info-card-${index}`}
                elevation={0}
                backgroundColor="inherit"
                icon={
                  <div
                    className={classes.categoryIconContainer}
                    style={{ backgroundColor: iconColors[index % iconColors.length] }}
                  >
                    <img src={card.fields.image.fields.file.url} alt={card.fields.image.fields.file.title} />
                  </div>
                }
                title={card.fields.title}
                linkTo={card.fields.navLink.fields.url}
                linkText={card.fields.linkText}
                classes={{ cardContent: classes.categoryCards }}
              >
                {renderContentfulRichText(card.fields.description)}
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>

      <div className={classes.rowSpacing}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h1" gutterBottom>
              {pageContent.launchBanner.fields.title}
            </Typography>
            {renderContentfulRichText(pageContent.launchBanner.fields.description)}

            <RoundedButton
              data-qa="api-marketplace-sign-up"
              href={pageContent.launchBanner.fields.cta.fields.navLink.fields.url}
              variant="contained"
              color="primary"
              className={classes.launchBannerCtaBtn}
            >
              {pageContent.heroBanner.fields.cta.fields.title}
            </RoundedButton>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Image
              src={pageContent.launchBanner.fields.media.fields.file.url}
              alt={pageContent.launchBanner.fields.media.title}
            />
          </Grid>
        </Grid>
      </div>
    </PageContainer>
  );
};

export default Home;
