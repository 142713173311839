import React from 'react';
import { Card as MuiCard, CardContent, CardProps, createStyles, Typography } from '@material-ui/core';
import NavLink from '../NavLink';
import { makeStyles } from '@material-ui/core/styles';

interface OwnProps {
  icon: React.ReactNode;
  children?: React.ReactNode;
  backgroundColor?: string;
  title: string;
  linkText: string;
  linkTo: string;
  classes?: Record<string, any>;
}

type Props = OwnProps & CardProps;

interface StyleProps {
  backgroundColor?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      borderRadius: '0.625rem',
      '&.MuiPaper-root': {
        backgroundColor: (props: StyleProps) => (props.backgroundColor ? props.backgroundColor : undefined),
      },
      '&.MuiPaper-rounded': {
        borderRadius: '0.625rem',
      },
    },
    contentRoot: {
      padding: '1.5rem',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  }),
);

const Card: React.FC<Props> = ({
  icon,
  children,
  title,
  linkText,
  linkTo,
  backgroundColor,
  classes = {},
  ...other
}: Props) => {
  const cardClasses = useStyles({ backgroundColor });
  return (
    <MuiCard classes={{ root: cardClasses.root }} {...other}>
      <CardContent classes={{ root: cardClasses.contentRoot }} className={classes.cardContent}>
        {icon}
        <Typography variant="h3" style={{ fontWeight: 'bold', margin: '2rem 0' }}>
          {title}
        </Typography>
        {children}
        <NavLink data-qa={`${linkTo}-card`} to={linkTo}>
          {linkText}
        </NavLink>
      </CardContent>
    </MuiCard>
  );
};

export default Card;
