import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import Colors from '../../../styles/theme/colors';
import { Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Container } from '../styles';
import { viewportSizes } from '../../../styles/theme';

interface Props {
  data: Record<string, any>;
}

const useStyles = makeStyles(() =>
  createStyles({
    groupText: {
      marginBottom: '1rem',
    },
    link: {
      textDecoration: 'none',
      marginBottom: '0.5rem',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    copyright: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      marginTop: '3rem',
    },
  }),
);

export const FooterDiv = styled.footer`
  background-color: ${Colors.BACKGROUND_GREY};
  min-height: 23rem;
  margin-top: 3rem;
`;

export const FooterContent = styled(Container)`
  padding: 1rem;
  min-height: 23rem;
  flex-flow: row wrap;
  display: flex;

  @media (min-width: ${viewportSizes.sm}px) {
    padding: 3rem;
  }
`;

const Footer: React.FC<Props> = ({ data }: Props) => {
  const classes = useStyles();
  const todaysDate = new Date();
  if (!data) {
    return null;
  }
  return (
    <FooterDiv>
      <FooterContent>
        <Grid container justify="center" spacing={3}>
          {data.fields.groups.map((group, index) => (
            <Grid data-qa={`footer-nav-link-container-${index}`} item xs={12} sm={3} md={2} key={index}>
              <Typography variant="body1" className={classes.groupText}>
                <b>{group.fields.groupText}</b>
              </Typography>
              {group.fields.navLinks.map((link) => (
                <Typography
                  data-qa={`footer-${link.fields.url}`}
                  variant="body1"
                  component={Link}
                  to={link.fields.url}
                  key={link.fields.url}
                  className={classes.link}
                  color="textPrimary"
                >
                  {link.fields.text}
                </Typography>
              ))}
            </Grid>
          ))}
        </Grid>
        <div className={classes.copyright}>
          <Typography variant="subtitle1">bp p.l.c. Copyright © {todaysDate.getFullYear()}</Typography>
        </div>
      </FooterContent>
    </FooterDiv>
  );
};

export default Footer;
