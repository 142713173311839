import React, { useState } from 'react';
import { createContext } from 'react';

export interface DrawerContextProps {
  drawerItems: Array<Record<string, string>>;
  setDrawerItems: (drawerItems: Array<Record<string, string>>) => void;
  showDesktopDrawer: boolean;
  toggleDesktopDrawer: (showDesktopDrawer: boolean) => void;
}

const defaultContext = {
  drawerItems: [],
  setDrawerItems: () => undefined,
  showDesktopDrawer: false,
  toggleDesktopDrawer: () => undefined,
};

export const DrawerContext = createContext<DrawerContextProps>(defaultContext);

interface Props {
  children: React.ReactNode;
}

export const DrawerContextProvider = (props: Props): JSX.Element => {
  const setDrawerItems = (drawerItems: Array<Record<string, string>>): void => {
    setState({ ...state, drawerItems });
  };
  const toggleDesktopDrawer = (showDesktopDrawer: boolean): void => {
    setState({ ...state, showDesktopDrawer });
  };
  const initState = {
    ...defaultContext,
    setDrawerItems,
    toggleDesktopDrawer,
  };
  const [state, setState] = useState<DrawerContextProps>(initState);
  return <DrawerContext.Provider value={state}>{props.children}</DrawerContext.Provider>;
};
