import styled from 'styled-components';
import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useCommonClasses = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      padding: theme.spacing(3),
    },
  }),
);

export const Container = styled.div`
  max-width: 1440px;
  margin: auto;
  width: 100%;
`;

export const PageContainer = styled.div`
  max-width: 1440px;
  width: 100%;
  padding: 0 1rem;
  margin: 0 auto;
}
`;

export const PaddingBottom = styled.div`
  padding-bottom: 1rem;
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  display: block;
`;
