import { useEffect, useState } from 'react';
import { getTheme } from '../../styles/theme';

export function useToggleTheme(): [string, () => void] {
  const [theme, setTheme] = useState<string>('light');

  const toggleDarkMode = (): void => {
    const {
      palette: { type },
    } = getTheme(theme);
    setLocaleStorage(type === 'light' ? 'dark' : 'light');
  };

  const setLocaleStorage = (mode: string): void => {
    window.localStorage.setItem('theme', mode);
    setTheme(mode);
  };

  useEffect(() => {
    const localTheme = window.localStorage.getItem('theme');

    if (localTheme) {
      setTheme(localTheme);
    } else {
      setLocaleStorage('light');
    }
  }, []);

  return [theme, toggleDarkMode];
}
