const Colors = {
  // PRIMARY
  BP_GREEN: '#006001',
  LIGHT_GREEN: '#098200',

  WHITE: '#FFFFFF',
  ALMOST_BLACK: '#2E3037',

  // FUNCTIONAL
  DARK_GREY: '#4B565E',
  SMP_GREY: '#6D7B84',
  LIGHT_GREY: '#939DA4',
  BACKGROUND_GREY: '#F6F6F6',

  // FEEDBACK
  ERROR: '#BB2E00',

  // ACCENT
  POPPY: '#F25936',
  MINT: '#26A671',
  LAVENDER: '#5845C8',
  SKY: '#11ADC8',
};

export default Colors;
